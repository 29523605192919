import React, { useContext, useEffect } from "react";
import IntroImageText from "../components/team/intro-image-text";
import ContentType7 from "../components/team/team-items";
import Footer from "../components/footer";

import introImage from "../images/team-hero.png";
import FirstLoadContext from "../context";

const Team = () => {
  const HeaderText = {
    header: "Team",
    description:
      "We brought together local visionaries and designers to ensure that Parkhouse\u00A0Residences at Uptown Newport surpasses any other residential community in Southern California. We invite you to meet our team.",
  };
  const TeamItems = [
    {
      title: "SHOPOFF REALTY INVESTMENTS",
      subTitle: "DEVELOPER",
      description:
        "Shopoff Realty Investments is a national real estate investment firm that primarily focuses on proactively generating appreciation through the repositioning of commercial, income-producing properties and the entitlement of land assets. Founded by chief executive officer William Shopoff in 1992, the story of Shopoff Realty Investments is one of success born of determination, vision and integrity",
    },
    {
      title: "WHA",
      subTitle: "ARCHITECT",
      description:
        "Original, relevant designs that work in the real world. Delivered consistently for nearly 40 years. We are WHA, a full-service land planning and architectural design firm with offices in Orange County, Los Angeles and the Bay Area—and trusted by agencies, builders and developers for the collaborative creation of projects rich in diversity, inventiveness and forward-thinking solutions.",
    },
    {
      title: "BLACKBAND DESIGN",
      subTitle: "INTERIOR DESIGNER",
      description:
        "Blackband Design is a full-service, internationally awarded interior design firm led by husband and wife duo, Greg and Wendy Blackband. Since 2003, Blackband Design has brought a fresh, sophisticated and unpretentious style to homes across Southern California and beyond while offering a personalized approach to every client.",
    },
    {
      title: "BRIGHTVIEW DESIGN GROUP",
      subTitle: "LANDSCAPE ARCHITECT",
      description:
        "The nations’s largest integrated landscape services company. The teams’s creative influence has led to the design of a broad range of widely-recognized projects including luxury resorts, parks and civic spaces, master-planned communities, sports complexes, mixed-use developments, and themed environments. BrightView Design Group differentiates itself by providing boutique-style expertise backed by the resources and support of a trusted, well-respected company known for delivering the nation’s best landscape contracting and services.",
    },
    {
      title: "POLARIS PACIFIC",
      subTitle: "SALES & MARKETING",
      description:
        "Polaris Pacific is the leading real estate sales and marketing group for today’s high-density residential communities. Drawing on a 30-year legacy of success in the Western United States, Polaris Pacific specializes in new development, leveraging the latest tools and technology to create a clear path forward. Long-term client relationships with industry leaders provide the company’s platform for innovation, establishing Polaris Pacific as the irrefutable expert in major urban markets.",
    },
    {
      title: "DBOX",
      subTitle: "BRANDING & CREATIVE",
      description:
        "DBOX builds brands and develops immersive marketing campaigns for the fields of property development, architecture and the arts. Since being founded in 1996, DBOX has received numerous awards for their work which has been exhibited at some of the world’s most respected art institutions.",
    },
  ];
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);
  return (
    <>
      <IntroImageText
        header={HeaderText.header}
        description={HeaderText.description}
        image={introImage}
      />
      <ContentType7 teamItems={TeamItems} />
      <Footer />
    </>
  );
};

export default Team;
