import React, { FC, useEffect, useRef } from "react";
import {
  TeamItemWrapper,
  TeamItemTitle,
  TeamItemSubTitle,
  TeamItemDescription,
} from "../index.styled";
import { ITeamItemProps } from "../../../../interfaces/team-item-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const TeamItem: FC<ITeamItemProps> = ({ ...props }) => {
  const teamItemWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: teamItemWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=30% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          teamItemWrapperRef?.current || "",
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  });

  return (
    <TeamItemWrapper ref={teamItemWrapperRef}>
      <TeamItemTitle>{props.title}</TeamItemTitle>
      <TeamItemSubTitle>{props.subTitle}</TeamItemSubTitle>
      <TeamItemDescription>{props.description}</TeamItemDescription>
    </TeamItemWrapper>
  );
};

export default TeamItem;
