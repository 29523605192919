import React, { FC } from "react";
import TeamItem from "./team-item";
import { TeamItemsWrapper, TeamItemsColumn } from "./index.styled";
import { ITeamItemsProps } from "../../../interfaces/team-items-props";

const TeamItems: FC<ITeamItemsProps> = ({ teamItems }) => {
  return (
    <TeamItemsWrapper>
      <TeamItemsColumn>
        {teamItems.map((item, i) => {
          return (
            <TeamItem
              title={item.title}
              subTitle={item.subTitle}
              description={item.description}
              key={item.title + i}
            />
          );
        })}
      </TeamItemsColumn>
    </TeamItemsWrapper>
  );
};

export default TeamItems;
