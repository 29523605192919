import React, { FC, useEffect, useRef } from "react";
import {
  IntroImageTextWrapper,
  HeaderColumn,
  DescriptionColumn,
} from "./index.styled";
import { IIntroImageTextProps } from "../../../interfaces/intro-image-text-props";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const IntroImageText: FC<IIntroImageTextProps> = ({ ...props }) => {
  const IntroImageTextWrapperRef = useRef<HTMLDivElement>(null);
  const HeaderColumnRef = useRef<HTMLDivElement>(null);
  const DescriptionColumnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: IntroImageTextWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          HeaderColumnRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          DescriptionColumnRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.2"
        ),
    });
  });

  return (
    <IntroImageTextWrapper ref={IntroImageTextWrapperRef} image={props.image}>
      <HeaderColumn
        className={"body-copy-highlighted body-copy-highlighted-heading"}
        ref={HeaderColumnRef}
      >
        {props.header}
      </HeaderColumn>
      <DescriptionColumn
        className={"body-copy-highlighted body-copy-highlighted-desc"}
        ref={DescriptionColumnRef}
      >
        {props.description}
      </DescriptionColumn>
    </IntroImageTextWrapper>
  );
};

export default IntroImageText;
