import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const IntroImageTextWrapper = styled("div")<{ image: string }>`
  width: 100%;
  height: calc(100vh - 80px);
  position: relative;
  background-image: url(${(props: { image: string }) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 48px 6.5%;
  ${mediaMax.tabletLandscape`
    flex-wrap: wrap;
  `}
`;

export const HeaderColumn = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 13.5%;
  text-transform: uppercase;
  color: white;
  padding-right: 1.5%;
  ${mediaMax.tabletLandscape`
    justify-content: flex-start;
    width: 100%;
    font-size: 26px;
    line-height: 32px;
  `}
`;

export const DescriptionColumn = styled("div")`
  display: flex;
  align-items: center;
  width: 73%;
  color: white;
  ${mediaMax.tabletLandscape`
    align-items: baseline;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    font-size: 26px;
    line-height: 36px;
  `}
  ${mediaMax.phoneXL`
    font-size: 22px;
    line-height: 30px;
  `}
`;
