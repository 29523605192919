import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const TeamItemsWrapper = styled("div")`
  width: 100%;
  background-color: #c9cfc7;
  padding: 120px 0 72px;
  display: flex;
  justify-content: center;
  ${mediaMax.tabletLandscape`
    padding: 80px 0 48px;
  `}
  ${mediaMax.phoneXL`
    padding: 80px 0 0;
  `}
`;

export const TeamItemsColumn = styled("div")`
  width: 51%;
  ${mediaMax.tabletLandscape`
    max-width: 500px;
    width: 90%
  `}
`;

export const TeamItemWrapper = styled("div")`
  padding-bottom: 72px;
  color: #434b42;
`;

export const TeamItemTitle = styled("h3")`
  padding-bottom: 12px;
  font: normal normal 100 24px/29px Optima;
  letter-spacing: 3.6px;
  text-transform: uppercase;
`;

export const TeamItemSubTitle = styled("h4")`
  padding-bottom: 48px;
  font: normal normal 100 16px/19px Optima;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  ${mediaMax.tabletLandscape`
    padding-bottom: 40px;  
  `}
`;
export const TeamItemDescription = styled("p")`
  font: normal normal 100 16px/19px Optima;
  letter-spacing: 0;
`;
